import React from 'react';

function Notification({ text }) {
  return (
    <article class="message is-info">
      <div class="message-body">{text}</div>
    </article>
  );
}

export default Notification;
