import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

function LatestBlogs() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { fields: { content_type: { eq: "blog-post" } } }
        limit: 5
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const blogs = data.allMarkdownRemark.edges.map((edge, index) => {
    const { node } = edge;

    return (
      <div key={index}>
        <Link className="blog-link" to={node.fields.slug}>
          {node.frontmatter.title}
        </Link>
        <hr style={{ margin: '1rem 0' }} />
      </div>
    );
  });

  return (
    <div style={{ marginTop: '50px' }} className="">
      <p className="subtitle sidebar">Seneste indlæg</p>
      <hr style={{ margin: '1rem 0' }} />
      {blogs}
    </div>
  );
}

export default LatestBlogs;
