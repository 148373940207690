import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import LatestBlogs from '../components/blog/LatestBlogs';
import Content, { HTMLContent } from '../components/Content';
import Notification from '../components/Notification';
import Seo from '../components/seo';
import PageHeader from '../components/PageHeader';

const IndexPageTemplate = ({
  title,
  subtitle,
  content,
  contentComponent,
  notification,
  image,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-10">
          <PageHeader title={title} subtitle={subtitle} />
          <hr />
        </div>
      </div>
      <div className="columns">
        <div className="column is-6 is-offset-1">
          {notification && <Notification text={notification} />}
          <PageContent className="content " content={content} />
        </div>
        <div className="column is-3 is-offset-1">
          <Link to="/profil/">
            <Img
              alt="Psykolog Jens Henrik Thomsen"
              className="book-image full-width-image"
              fluid={image.fluid}
            />
          </Link>
          <LatestBlogs />
        </div>
      </div>
    </>
  );
};

function IndexPage({ data }) {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Seo
        title="Psykolog Jens Henrik Thomsen"
        description={post.frontmatter.description}
      />
      <IndexPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.description}
        content={post.html}
        notification={post.frontmatter.notification}
        image={data.file.childImageSharp}
      />
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        notification
      }
    }
    file(relativePath: { eq: "profil.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
